import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './ordemServicoForm';
import FormExecucao from './ordemServicoExecucaoForm';
import Selecionar from './ordemServicoSelecionar';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Aguardando from '../common/template/aguardando';

import { 
	setModoTela, initForm, salvar, excluir, salvarExecucao, setFiltro,
	getLista, getListaSetor, getListaMotorista, getListaVeiculo, gerarPdf, gerarPdfNovo, getListaChecklist, getChecklistPergunta, getListaUsuario
} from './ordemServicoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

class OrdemServico extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		status: 1,
		id_usuario_abertura: -1
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaSetor();
		this.props.getListaMotorista();
		this.props.getListaVeiculo();
		this.props.getListaChecklist();
		this.props.getChecklistPergunta();
		this.props.getListaUsuario();

		let idInterval = setInterval(() => {
			if (this.props.sessao) {
				this.setState({
					...this.state,
					id_usuario_abertura: this.props.sessao.id
				});
				clearInterval(idInterval);
			}
		}, 500);
    }

    render() {
        return (
            <div>
				<Aguardando aguardando={this.props.aguardando} />
                <Content>
					{this.props.modoTela == 'selecionar' ? (
						<Selecionar />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
					{this.props.modoTela == 'execucao' ? (
						<FormExecucao onSubmit={this.props.salvarExecucao} />
					) : null}
                </Content>
            </div>
        )
    }

	lista() {

		const mobile = window.innerWidth < 800;

		const usuarioBorracharia = this.props.sessao && this.props.sessao.id == 95;

		return (
			<ContentCard>
				<ContentCardHeader>
					{!usuarioBorracharia &&
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={this.props.permissoes.acesso_despesa != 2}
								event={() => {
									this.props.setModoTela('selecionar', {});
								}} />
						</Grid>
					</Row>}
					{!usuarioBorracharia &&
					<Row style={{ marginTop: 8 }}>
						<Select
							label='Status'
							cols='12 6 4 3'
							options={[{ id: '1', valor: 'Aberta' }, { id: '2', valor: 'Finalizada' }, { id: '3', valor: 'Ambas' }]}
							value={this.state.status}
							onChange={item => {
								this.setState({ ...this.state, status: item })
							}} />
							
						{(this.props.listaUsuario.length && this.state.id_usuario_abertura != -1) &&
						<Select
							label='Usuário' 
							placeholder='Informe o usuário'
							cols='12 6 4 3'
							options={this.props.listaUsuario}
							value={this.state.id_usuario_abertura}
							onChange={item => {
								this.setState({ ...this.state, id_usuario_abertura: item })
							}} />}
					</Row>}
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							label='Data Inicial' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							}} />
						
						<Grid cols='6 6 4 2' style={{ paddingTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								disabled={false}
								event={() => {
									this.props.getLista();
								}} />
						</Grid>
					</Row>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						
							<Tr>
								<Th>Nº OS</Th>
								{!mobile &&
								<Th>Data Emissão OS</Th>}
								<Th>Setor</Th>
								{!mobile &&
								<Th>Responsável</Th>}
								{!mobile &&
								<Th>Motorista</Th>}
								<Th alignCenter>Veículo</Th>
								{!mobile &&
								<Th alignCenter>Início Manutenção</Th>}
								{!mobile &&
								<Th alignCenter>Fim Manutenção</Th>}
								{!mobile &&
								<Th>Usuário</Th>}
								<Th></Th>
							</Tr>
						
						<TBody>
							{this.props.lista
								.filter(item => {
									if (usuarioBorracharia) {
										if (item.datahora_finalizacao) {
											return false;
										}
										//1 - Borracharia
										if (item.id_setor != 1) {
											return false;
										}
									} else {
										if (this.state.status != 3 && ((this.state.status == 1 && item.datahora_finalizacao) || (this.state.status == 2 && !item.datahora_finalizacao))) {
											return false;
										}
										if (this.state.id_usuario_abertura && item.id_usuario_abertura != this.state.id_usuario_abertura) {
											return false;
										}
									}
									if (this.state.pesquisar
										&& !(`${JSON.stringify(item)} ${item.nome_setor} ${item.nome_responsavel}
											${item.nome_motorista} ${item.fornecedor} ${item.pagamento_motorista ? 'Motorista' : 'Empresa'} ${item.nome_despesa_produto}
											${item.nome_usuario_aprovacao}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
										return false;
									}
									return true;
								}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.id}</Td>
									{!mobile &&
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_abertura)}</Td>}
									<Td>{item.nome_setor}</Td>
									{!mobile &&
									<Td>{item.nome_responsavel}</Td>}
									{!mobile &&
									<Td>{item.nome_motorista}</Td>}
									<Td alignCenter>{item.placa_veiculo}</Td>
									{!mobile &&
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio_manutencao)}</Td>}
									{!mobile &&
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_fim_manutencao)}</Td>}
									{!mobile &&
									<Td>{item.nome_usuario_abertura}</Td>}
									<Td alignRight minWidth={100}>
										{/*<ButtonTable
											type={'primary'}
											icon={'fas fa-clipboard-list'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.gerarPdf({
													...item,
													data_ordem_servico: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abertura),
													datahora_inicio_manutencao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio_manutencao),
													datahora_fim_manutencao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_fim_manutencao)
												});
											}} />*/}

										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id || mobile}
											disabled={this.props.permissoes.acesso_despesa != 2 && !usuarioBorracharia}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_inicio_manutencao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio_manutencao),
													datahora_fim_manutencao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_fim_manutencao)
												});
												this.props.initForm({
													...item,
													datahora_inicio_manutencao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio_manutencao),
													datahora_fim_manutencao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_fim_manutencao)
												});
											}} />
											
										{!mobile &&
										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id || mobile}
											disabled={this.props.permissoes.acesso_despesa != 2}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_inicio_manutencao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio_manutencao),
													datahora_fim_manutencao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_fim_manutencao)
												});
												this.props.initForm({
													...item,
													datahora_inicio_manutencao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio_manutencao),
													datahora_fim_manutencao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_fim_manutencao)
												});
											}} />}
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.ordemServico.modoTela,
	aguardando: state.ordemServico.aguardando,
	lista: state.ordemServico.lista,
	filtro: state.ordemServico.filtro,
	permissoes: state.usuario.permissoes,
	listaUsuario: state.ordemServico.listaUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, excluir, salvarExecucao, setFiltro,
	getLista, getListaSetor, getListaMotorista, getListaVeiculo, gerarPdf, gerarPdfNovo, getListaChecklist, getChecklistPergunta, getListaUsuario
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrdemServico);
