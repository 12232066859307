import React, { useState } from 'react';

const ClipboardImageHandler = (props) => {

  const [isFocused, setIsFocused] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  // Função para processar a imagem e convertê-la para Base64
  const processImageFile = (file) => {
    
    if (!file || ((props.types || []).length && !(props.types || []).filter(type => file.type.includes(type)).length)) {
      alert(props.msgErro);
      return;
    } 

    const reader = new FileReader();
    reader.onload = (e) => {
      props.onChange((e.target.result));
    };
    reader.readAsDataURL(file);
  };

  // Manipula colagem de imagens
  const handlePaste = (event) => {
    const clipboardItems = event.clipboardData.items;
    for (let item of clipboardItems) {
      if ((props.types || []).length && (props.types || []).filter(type => item.type.includes(type)).length) {
        processImageFile(item.getAsFile());
        return;
      }
    }
    alert(props.msgErro);
  };

  // Manipula arrastar e soltar
  const handleDrop = (event) => {
    event.preventDefault(); // Previne comportamento padrão do navegador
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      processImageFile(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  };

  // Previne o comportamento padrão no arrastar
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    setIsFocused(false);
    setIsDragging(false); // Remove destaque ao perder o foco
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (((props.types || []).length && !(props.types || []).filter(type => file.type.includes(type)).length)) {
        alert(props.msgErro);
        return;
      } 
      processImageFile(file);
    }
  };

  return (
    <div
      tabIndex={0} // Torna o div focável para eventos de teclado
      onPaste={handlePaste}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px dashed',
        borderColor: isDragging ? '#00f' : isFocused ? '#0f0' : '#ccc',
        backgroundColor: isDragging ? '#e0f7fa' : isFocused ? '#e8f5e9' : '#fff',
        padding: 20,
        textAlign: 'center',
        outline: 'none'
      }}
    >
      {props.label}

      <b style={{ marginTop: 4, marginBottom: 6 }}></b>

      <button
        type='button'
        class='btn btn-block btn-secondary btn-sm'
        style={{
          width: 150
        }}
        onClick={() => document.getElementById('fileInputClipboardImagem').click()} >
        Selecionar Arquivo
      </button>

       <input
         id='fileInputClipboardImagem'
         type='file'
         onChange={handleFileSelect}
         style={{ display: 'none' }} />
    </div>
  );
};

export default ClipboardImageHandler;
