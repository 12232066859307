import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setFiltro(filtro) {
	return {
		type: 'DASHBOARD_FINANCEIRO_FILTRO',
		payload: filtro,
	};
}

export function getListaEficiencia() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_final);
        axios.get(`${consts.API_URL}/ordemServico/listarEficiencia?data_inicial=${dataInicial}&data_final=${dataFinal}
        `).then(resp => {
			dispatch({
				type: 'DASHBOARD_FINANCEIRO_EFICIENCIA_LISTADA',
                payload: resp
			});
		}).catch(ex => {
			console.log(ex);
		});
    }
}

export function getListaNegociacaoUtilizada() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_final);
        axios.get(`${consts.API_URL}/abastecida/listarDashboardNegociacaoUtilizada?data_inicial=${dataInicial}&data_final=${dataFinal}`).then(resp => {
            dispatch({
                type: 'DASHBOARD_FINANCEIRO_NEGOCIACAO_UTILIZADA_LISTADA',
                payload: resp
            });
        }).catch(ex => {
            console.log(ex);
        });
    }
}

export function getListaNegociacaoStatus() {

    return (dispatch, getState) => {
        axios.get(`${consts.API_URL}/postoNegociacao/listarDashboardNegociacaoStatus`).then(resp => {
            dispatch({
                type: 'DASHBOARD_FINANCEIRO_NEGOCIACAO_STATUS_LISTADA',
                payload: resp
            });
        }).catch(ex => {
            console.log(ex);
        });
    }
}

export function getListaNegociacaoForma() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_final);
        axios.get(`${consts.API_URL}/abastecida/listarDashboardNegociacaoForma?data_inicial=${dataInicial}&data_final=${dataFinal}`).then(resp => {
            dispatch({
                type: 'DASHBOARD_FINANCEIRO_NEGOCIACAO_FORMA_LISTADA',
                payload: resp
            });
        }).catch(ex => {
            console.log(ex);
        });
    }
}

export function getListaTopRedes() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_final);
        axios.get(`${consts.API_URL}/abastecida/listarTopRedes?data_inicial=${dataInicial}&data_final=${dataFinal}`).then(resp => {
            dispatch({
                type: 'DASHBOARD_FINANCEIRO_TOP_REDES_LISTADA',
                payload: resp
            });
        }).catch(ex => {
            console.log(ex);
        });
    }
}

export function getListaTopPostos() {

    return (dispatch, getState) => {
        const dataInicial = DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_inicial);
        const dataFinal = DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboardFinanceiro.filtro.data_final);
        axios.get(`${consts.API_URL}/abastecida/listarTopPostos?data_inicial=${dataInicial}&data_final=${dataFinal}`).then(resp => {
            dispatch({
                type: 'DASHBOARD_FINANCEIRO_TOP_POSTOS_LISTADA',
                payload: resp
            });
        }).catch(ex => {
            console.log(ex);
        });
    }
}


